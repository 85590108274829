import { Link } from 'gatsby';
import React from 'react';
import { ColumnBox } from '../components/Box';
import { FlatButton } from '../components/Button';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import Text, { TextWithBorder } from '../components/Text';
import route from '../constants/route';
import LayoutContext from '../contexts/LayoutContext';

const NotFoundPage = () => {
  const { isMobile } = React.useContext(LayoutContext);
  return (
    <Layout isMobile={isMobile}>
      <SEO title="404: Not found" pathname="/404" />
      <ColumnBox bg="very-light-pink" alignItems="center" pt={208} pb={100}>
        <TextWithBorder
          color="dusty-orange"
          fontSize={7}
          fontWeight={900}
          letterSpacing={1.2}
          textAlign="center"
          mb={30}
        >
          404 Not Found
        </TextWithBorder>
        <Text fontSize={5} fontWeight="bold" textAlign="center" mb={32}>
          お探しのページは見つかりませんでした。
        </Text>
        <Text fontSize={3} fontWeight={500} textAlign="center" mb={207}>
          リンクに問題があるか、 削除された可能性がございます。
        </Text>
        <Link to={route.root.path}>
          <FlatButton width={300} height={55} label="トップページへ" />
        </Link>
      </ColumnBox>
    </Layout>
  );
};

export default NotFoundPage;
